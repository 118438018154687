import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import whitebranch from "../img/large-branch-white.png";

const HenryFormSubmitted = ({ data }) => {
  const siteTitle = "Successfully Submitted";
  const siteDescription = data.site.siteMetadata.description;

  return (
    <Layout location={data.location} title={siteTitle}>
      <SEO title={siteTitle} description={siteDescription} />

      <div class="service-section4">
        <div class="container">
          <div className="row justify-content-center">
            <div class="col-md-10 col-lg-8">
              <div class="bg text-center">
                <img alt="whitebranch" src={whitebranch} />
              </div>
              <h1 class="section-h2 text-white">
                <em>Form successfully submitted.</em>
              </h1>
              <p className="service-p text-white">
                Someone from Journey Financial Services will call you within 24
                hours to confirm appointment, email and send some introductory
                information. There is no fee for this consultation.
              </p>
              <Link class="bttn float-center ty-btn" to="/">
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HenryFormSubmitted;

export const henryFormSubmittedQuery = graphql`
  query henryFormSubmittedQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`;
